// colors
$default-secondary-toolbar-bg-clr: #cccccc;
$secondary-light-clr: #f2f2f2;
$border-clr: #000;
$default-background-clr: #fff;

// sizing
$default-padding: 16px;
$default-margin: 8px;

:root {
--mat-toolbar-title-text-font: 'Inter', sans-serif;
--mdc-text-button-label-text-font: 'Inter', sans-serif;
--mdc-text-button-label-text-tracking: 0;
--mdc-filled-button-label-text-font: 'Inter', sans-serif;
--mdc-filled-button-label-text-tracking: 0;
--mat-table-row-item-label-text-font: 'Inter', sans-serif;
--mdc-outlined-button-label-text-font: 'Inter', sans-serif;
--mdc-outlined-button-label-text-weight: 500;
--mdc-outlined-button-label-text-tracking: 0;
--mat-menu-item-label-text-font: 'Inter', sans-serif;
--mat-menu-item-label-text-weight: 500;
--mat-menu-item-label-text-tracking: 0;
--mat-menu-item-label-text-size:14px;
--mat-menu-item-label-text-line-height: 18px;
--mat-table-header-headline-size: 11px;
}