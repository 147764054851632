@use '@angular/material' as mat;

@include mat.core();
@import 'scss/theme/theme';
@import 'scss/theme/variables';

@import '@cm/ui-modules/assets/common';
@import '@cm/ui-modules/assets/variables';
@import '@cm/ui-modules/assets/breakpoints';
@import '@cm/ui-modules/assets/colors';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;700;900&display=swap');

:root {
  --mat-paginator-container-text-font: 'Inter', sans-serif;
  --mat-bottom-sheet-container-text-font: 'Inter', sans-serif;
  --mdc-filled-text-field-label-text-font: 'Inter', sans-serif;
  --mat-stepper-container-text-font: 'Inter', sans-serif;
  --mat-form-field-container-text-font: 'Inter', sans-serif;
  --mat-datepicker-calendar-text-font: 'Inter', sans-serif;
  --mat-tree-node-text-font: 'Inter', sans-serif;
  --mat-badge-text-font: 'Inter', sans-serif;
  --mat-expansion-header-text-font: 'Inter', sans-serif;
  --mdc-text-button-label-text-font: 'Inter', sans-serif;
  --mdc-snackbar-supporting-text-font: 'Inter', sans-serif;
  --mat-toolbar-title-text-font: 'Inter', sans-serif;
  --mdc-filled-button-label-text-font: 'Inter', sans-serif;

  --mat-table-header-headline-font: 'Inter', sans-serif;
  --mat-table-header-headline-weight: 700;
  --mat-table-row-item-label-text-font: 'Inter', sans-serif;
  --mat-table-row-item-label-text-tracking: unset;
  --mat-table-row-item-label-text-size: 13px;
  --mat-table-row-item-label-text-weight: 500;
  --mat-table-footer-supporting-text-font: 'Inter', sans-serif;

  --mdc-outlined-button-label-text-font: 'Inter', sans-serif;
  --mat-menu-item-label-text-font: 'Inter', sans-serif;


  --mdc-text-button-label-text-tracking: 0;
  --mdc-filled-button-label-text-tracking: 0;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-tracking: 0;
  --mat-menu-item-label-text-weight: 500;
  --mat-menu-item-label-text-tracking: 0;
  --mat-menu-item-label-text-size:14px;
  --mat-menu-item-label-text-line-height: 16px;
  --mat-table-header-headline-size: 11px;

  // .mat-mdc-unelevated-button{
  //   border:1px black solid;
  // }
  // .mat-warn{
  //   border:0;
  // }

}


html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
}

* {
  box-sizing: border-box;
}

.center{
  justify-content: center;
  align-items: center;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 4rem;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

@for $i from 10 through 100 {
  .w-#{$i} {
    width: unquote("#{$i}%");
  }
}

div[actions] {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;

  div[item] {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:10px;
    margin-right:1rem;
    
  }
  button {
    font-size: 14px;
    font-weight:500;
    .mdc-button__label{
      font-weight:500;
    }
    &.active {
      font-weight:500;
      background-color: #1caf9a;
      color: black;
    }
  }
}


.edit-object-modal{
  width: 100%;
}
.contentpanel {
  padding: 0px;
  position: initial;
}

.cm-view-container {
  background-color: $primary-background;
  height: 100%;
}

.cm-data-table {
  box-shadow: 0px 3px 1px -2px rgba(155, 39, 39, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

cm-ui-table{
  border:2px black solid;
}

.container-box{
  border:2px solid black;
  margin: 0 1rem;
  display:flex;
  flex-direction: column;
  .nav{
    padding:15px;
    display:flex;
    background-color: #f2f2f2;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f2f2;
    .title{
      font-size: 14px;
      font-weight: 700;
    }
  }
}
.container-component{
  border:2px solid black;
  // padding:15px;
  margin: 0 1rem;
}

.cm-fab-menu {
  position: absolute;
  float: right;
  right: 15px;
  bottom: 10px;
}

.dt-toolbar {
  background-color: #ffffff !important; // TODO: do ustawienia w komponencie datatable
}

// Hide admin panel page header
.pageheader {
  display: none;
}

// Language picker
.dropdown-menu {
  z-index: 1002;
}

.h-100 {
  height: 100%;
}
.d-f {
  display: flex;
}
.f-col {
  flex-direction: column;
}
.p-16 {
  padding: 16px;
}
.app-spacer {
  flex: 1 1 auto;
}
.f-1 {
  flex: 1;
}
.flex-grow{
  flex-grow: 1;
}
.z-100 {
  z-index: 100;
}
.z-200 {
  z-index: 200;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.m-l-16 {
  margin-left: 16px !important;
}
.list-header {
  @extend .mat-elevation-z1;
  background-color: white;
  z-index: 200;
  position: relative;
}
.list-content {
  display: block;
  height: 100%;
  padding: 16px;
  overflow: auto;
  z-index: 100;
  .list-content-table-wrapper {
    //@extend .mat-elevation-z2;
    border: 2px solid #000;
  }
}
// panel fix
body > section:first-child {
  height: 100%;
}
.mainpanel {
  min-height: initial !important;
  height: 100% !important;
}
.contentpanel {
  height: calc(100% - 50px - 1px);
  //border-top: 1px solid rgba(0, 0, 0, 0.12);
}

h2.app-header {
  margin-bottom: 0;
  margin-top: 0;
}
// overwrite panel bootstrap
.mat-menu-content {
  a,
  a:hover {
    color: black;
    text-decoration: none;
  }
}

.mat-form-field-underline {
  border: 0px;
}
.mat-form-field {
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
}

mat-toolbar.list-header {
  background-color: #fff; // Change toolbar background color
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 10px 5px 1px;
  box-shadow: none;

  h2 {
    font-size: 28px;
    font-weight: 700;
    color: #1d2939;
    letter-spacing: -0.5px;
    margin: 0;
    margin-left: 25px;
  }

  .mat-raised-button {
    box-shadow: none;
    border: 1px solid #1caf9a;
  }
  a {
    i {
      margin-right: 5px;
    }
  }
  .mat-primary {
    background-color: #fff;
    border-color: #000;
    color: #000;
    margin-left: 20px;
    &:hover {
      background-color: #1caf9a !important;
      border-color: #17a08c !important;
      color: #fff !important;
    }
  }
}

$base-unit: 1rem;
$base-px: 5px;
@mixin mt($value) {
  margin-top: $value * $base-unit;
}
@mixin mb($value) {
  margin-bottom: $value * $base-unit;
}
@mixin ml($value) {
  margin-left: $value * $base-px;
}
@mixin py($value) {
  padding-bottom: $value * $base-px;
  padding-top: $value * $base-px;
}
@mixin pt($value) {
  padding-top: $value * $base-px;
}
@for $i from 1 through 10 {
  .ml-#{$i} {
    @include ml($i);
  }
  .mt-#{$i} {
    @include mt($i);
  }
  .mb-#{$i} {
    @include mb($i);
  }
  .py-#{$i} {
    @include py($i);
  }
  .pt-#{$i} {
    @include pt($i);
  }
}

.container-wrapper {
  padding: 16px;
  flex: 1;
  position: relative;
  //background-color: $secondary-light-clr;
  @include respond-below('small') {
    padding: 8px;
  }
  h2 {
    font-weight: bold;
  }
}

.navbar-title {
  padding: 8px;
}

.mat-toolbar .sub-nav-title {
  display: flex;
  align-items: center;
  &--text {
    margin-right: 20px;
    font-size: 28px;
    font-weight: 700;
    //color: #1d2939;
    letter-spacing: -0.5px;
  }
  &--button {
    display: flex;
    align-items: center;
    justify-content: center;
    &--icon {
      font-size: 22px;
      width: auto;
    }
  }
}

cm-ui-table > cm-ui-table-header > div.button-wrapper > button {
  margin-left:5px;
}

.campaign-list-wrapper {
  .mat-row {
    cursor: pointer;
  }
  cm-ui-table > cm-ui-table-header > div.button-wrapper > button:nth-child(1) {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 15px;
    //line-height: 34px;
  }

  cm-ui-table > div > table > tbody > tr > td.value-cell.mat-column-name {
    max-width: 100%;
    white-space: normal;
    width: auto;
  }
  cm-ui-table > div > table > tbody > tr > td.value-cell {
    width: 5%;
    max-width: inherit;
  }
  @media (max-width: 991px) {
    cm-ui-table > div > table > tbody > tr > td.value-cell.mat-column-name {
      max-width: 100%;
      min-width: 230px;
    }
  }
  @media (max-width: 565px) {
    cm-ui-table > div > table > tbody > tr > td.value-cell.mat-column-name {
      min-width: 140px;
    }
  }
}
.meeting-list-wrapper {
  .mat-row {
    cursor: pointer;
  }
}

.mat-raised-button:hover {
  color: initial;
  text-decoration: initial;
}
.default-panel-wrapper{
  --mdc-dialog-container-shape: 0px;
}

.inactivity-modal-panel {
  .mat-dialog-container {
    padding: 0;
    overflow: hidden;
  }
}

.cdk-overlay-backdrop.inactivity-modal-overlay {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.8) !important;
}

@for $i from 1 through 10 {
  .gap-#{$i} {
    gap: #{$i*5}px;
  }
}

@for $i from 1 through 10 {
  .p-#{$i} {
    padding: #{$i*5}px;
  }
}

.smallPanel{
  mat-dialog-container {
    .mdc-dialog__container{
      .mat-mdc-dialog-surface{
        padding:1rem;
        h3{
          font-weight:900;
        }
      }

    }
    max-width: 700px;
  }
}

.largePanel{
  mat-dialog-container {
    .mdc-dialog__container{
      .mat-mdc-dialog-surface{
        padding:1rem;
        h3{
          font-weight:900;
        }
      }

    }
    max-width: 1200px;
  }
}
