// https://material.io/design/layout/responsive-layout-grid.html#breakpoints
$breakpoints: (
  'xsmall': 599px,
  'small': 1023px,
  'medium': 1439px,
  'md': 767px
);

@function breakpoint($breakpoint-name) {
  $breakpoint-value: map-get($breakpoints, $breakpoint-name);
  @if $breakpoint-value {
    @return $breakpoint-value;
  }
  @warn "Breakpoint '#{$breakpoint-name}' not found in $breakpoints";
}

@mixin respond-above($breakpoint-name) {
  $breakpoint-value: breakpoint($breakpoint-name);

  @if $breakpoint-value {
    @media (min-width: $breakpoint-value) {
      @content;
    }
  }
}

@mixin respond-below($breakpoint-name) {
  $breakpoint-value: breakpoint($breakpoint-name);

  @if $breakpoint-value {
    @media (max-width: $breakpoint-value) {
      @content;
    }
  }
}
