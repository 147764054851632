@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
@import "breakpoints";
@import "variables";
@import "colors";

// Apply Material Symbols Outlined to all mat-icon elements
mat-icon {
  font-family: 'Material Symbols Outlined', sans-serif !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

:host{
  font-family: "Inter", sans-serif;
}

.mat-mdc-menu-item{
  font-family: 'Inter', sans-serif;
  font-size:14px;
  font-weight:500 ;
}

.ui-navbar-main-menu {
    // border-radius: unset !important;
    // box-shadow: none !important;
    // border: 2px solid black;  
    // padding: 0px !important;  
  
  .menu-content-wrapper {
    padding: $default-padding;
    h5 {
      margin: 0 0 16px;
    }
  }
}

cm-ui-loader {
  .mat-spinner circle {
    stroke: black;
  }
}

.hidden-sm {
  @include respond-below('xsmall') {
    display: none;
  }
}

.hidden-below-md {
  @include respond-below('md') {
    display: none;
  }
}

.hidden-md {
  @include respond-above('xsmall') {
    display: none;
  }
}

cm-ui-table-column-selector{
    padding: 1rem;
    // border: 2px solid #000;
    // box-shadow: none;
}

.ui-custom-panel .mat-dialog-container {
  display: flex;

  flex-direction: column;
  padding: 5rem;
  max-width: 100%;
  max-height: 90vh;
  border-radius: 0;
  overflow: hidden;
  @include respond-below('xsmall') {
    padding: $default-padding;
  }

  .ui-dialog-actions {
    margin-top: $default-padding;
  }

}
cm-ui-table-column-selector {
  .custom-field-columns {
    .mat-checkbox-layout {
      width: 100%;
      .mat-checkbox-label {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: calc($default-padding / 2);
      }
    }
  }
}
button.icon-and-text {
  span.mat-button-wrapper {
    display: flex;
    align-items: center;
  }
}

.warn-clr {
  color: $warn;
}
.mat-menu-item-submenu-trigger {
  padding-right: 0;
  .mat-icon {
    margin-right: 0;
    margin-left: 16px;
  }
  &::after {
    display: none;
  }
}
